import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "create-header d-sm-flex flex-row justify-content-between" }
const _hoisted_3 = { class: "mt-auto" }
const _hoisted_4 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_basic_select = _resolveComponent("table-basic-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("imessaging_iftdgn_cargo_type")), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              type: "text",
              class: "find-btn w-sm-200px",
              placeholder: `${_ctx.$t('isearch')}`,
              onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onSearch && $setup.onSearch(...args)))
            }, null, 40, _hoisted_4), [
              [_vModelText, $setup.search]
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_table_basic_select, {
      class: "mt-5",
      headers: $setup.header,
      data: $setup.dataObject,
      total: $setup.dataObject.length,
      onEventEmitButton: $setup.actionsButton
    }, null, 8, ["headers", "data", "total", "onEventEmitButton"])
  ]))
}